import requests from '../requests'
import { CONTACT_URL } from '@/assets/variables/endpoints'

export const state = () => ({
  dataTable: [],
  contacts: [],
  contactDetail: {
    name: '',
    source_type: '',
    created_at: '',
    contacts: [],
    error_messages: {},
  },
  contactLists: [],
  ids: '',
  filterStatus: null,
  uploadProgress: 0,
  exportList: [],
})

export const actions = {
  getContacts({ commit, state }, params) {
    // if (params.offset === 1) params.is_counted = true
    params.is_counted = true
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(CONTACT_URL, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          if (params.offset === 1)
            commit('layouts/SET_TOTAL_COUNT', res.meta.pagination.total, {
              root: true,
            })
          commit('layouts/SET_META', res.meta, { root: true })
          commit('SET_DATATABLE', res.data)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        }
      )
  },
  getContactLists({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .getService(
        CONTACT_URL,
        { limit: 10000 },
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('SET_CONTACTS', res.data)
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  getContactDetail({ commit, state, dispatch }, params) {
    return new Promise((resolve) => {
      commit(
        'layouts/UPDATE_CONTENTLOADING',
        { type: 'content', status: true },
        { root: true }
      )
      requests.whatsapp
        .getService(
          CONTACT_URL + '/' + state.ids,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('SET_CONTACTINFO', res.data)
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
          }
        )
      dispatch('getContactListOnDetail', params)
    })
  },
  getContactListOnDetail({ commit, state }, params) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    if (params.offset === 1) params.is_counted = true
    requests.whatsapp
      .getService(
        CONTACT_URL + '/contacts/' + state.ids,
        { ...params, status: state.filterStatus },
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          if (params.offset === 1)
            commit('layouts/SET_TOTAL_COUNT', res.meta.pagination.total, {
              root: true,
            })
          commit('layouts/SET_META', res.meta, { root: true })
          commit('SET_CONTACTDETAIL', res.data)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        }
      )
  },
  createContact({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      const formdata = new FormData()
      formdata.append('name', params.name)
      formdata.append('source_type', 'spreadsheet')
      formdata.append('file', params.file, params.filename)
      this.$axios.setToken(this.$auth.getToken('hub'))
      this.$axios.setHeader('Access-Control-Allow-Origin', '*')
      this.$axios
        .post(CONTACT_URL + '/async', formdata, {
          onUploadProgress(uploadEvent) {
            const progress = Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            )
            commit('UPDATE_UPLOAD_PROGRESS', progress)
          },
        })
        .then((res) => {
          if (res.data.status === 'success') {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Your list is being uploaded, please wait',
                item: '',
                subItem: res.data.data.id,
                callback: {
                  text: 'OK',
                  method: 'redirectToContactDetail',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            commit('UPDATE_UPLOAD_PROGRESS', 0)
            resolve(res.data)
          } else {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: 'Either file and data is not supported, or your format number is incorrect (use 628xx instead of 08xx). Please kindly re-check again',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            commit('UPDATE_UPLOAD_PROGRESS', 0)
            reject(res.data)
          }
        })
        .catch((err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message:
                err.response.data.error.code === 429
                  ? 'Unable to upload contact'
                  : 'Error',
              item:
                err.response.data.error.code === 429
                  ? 'You can upload contact every 5 minutes. Please wait for a moment'
                  : err.response.data.error.messages[0] ||
                    'Something went wrong',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          reject(err)
        })
    })
  },
  deleteContact({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .deleteService(CONTACT_URL, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Deleted!',
              item: '',
              callback: {
                text: 'OK',
                method: 'getContacts',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  exportContactList({ commit }, params) {
    for (let i = 0; i <= 25; i++) {
      setTimeout(() => {
        commit('UPDATE_EXPORT_LIST', [
          { title: params.title, desc: params.desc, progress: i * 4 },
        ])
      }, 2000)
    }
  },
}

export const mutations = {
  SET_DATATABLE(state, payload) {
    state.dataTable = payload
  },
  SET_CONTACTS(state, payload) {
    state.contacts = payload
  },
  SET_CONTACTINFO(state, payload) {
    state.contactDetail = payload
  },
  SET_CONTACTDETAIL(state, payload) {
    state.contactLists = payload
  },
  SET_ID(state, payload) {
    state.ids = payload
  },
  UPDATE_DETAIL_STATUS(state, payload) {
    state.filterStatus = payload
  },
  UPDATE_UPLOAD_PROGRESS(state, payload) {
    state.uploadProgress = payload
  },
  UPDATE_EXPORT_LIST(state, payload) {
    state.exportList = payload
  },
}

export const getters = {}
