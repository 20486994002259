import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment'
import requests from '~/requests'
import { BILLINGS_URL } from '~/assets/variables/endpoints'

export const state = () => ({
  isLoadingChatbotUsageLog: false,
  responseChatbotUsageLog: [],
  filter: {
    start_at: moment(new Date()).format('YYYY-MM-DD'),
    end_at: moment(new Date()).format('YYYY-MM-DD'),
  },
  pagination: {
    chatbot_usage: {
      current: 1,
      limit: 10,
      total_page: 0,
      total: 0,
      next: false,
      prev: true,
    },
  },
})

export const getters = {
  getField,
}

export const actions = {
  getData({ commit }, params) {
    commit('UPDATE_LOADING_CHATBOT_USAGE', true)
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${BILLINGS_URL}/chatbot_ai/usage_log`, params.payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (res.data && res.data?.response) {
              commit('SET_DATA_CHATBOT_USAGE', res.data.response)
              commit('UPDATE_PAGINATION_PAGE', {
                type: params.type,
                current: res?.data?.pagination?.offset,
                total: res.data?.pagination?.total,
                limit: params.payload.limit,
              })
            } else {
              commit('SET_DATA_CHATBOT_USAGE', [])
            }
            commit('UPDATE_LOADING_CHATBOT_USAGE', false)
            resolve(true)
          },
          (err) => {
            commit('UPDATE_PAGINATION_PAGE', {
              type: params.type,
              current: 1,
              total: 0,
              limit: 10,
            })
            commit('SET_DATA_CHATBOT_USAGE', [])
            commit('UPDATE_LOADING_CHATBOT_USAGE', false)
            reject(err)
          }
        )
    })
  },
  updateData({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('UPDATE_LOADING_CHATBOT_USAGE', true)
      requests.whatsapp
        .getService(`${BILLINGS_URL}/chatbot_ai/usage_log`, params.payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (res.data && res.data?.response) {
              commit('SET_DATA_CHATBOT_USAGE', res.data.response)
              commit('UPDATE_PAGINATION_PAGE', {
                type: params.type,
                current: parseInt(res.data?.pagination?.offset),
                total: parseInt(res.data?.pagination?.total),
                limit: params.payload.limit,
              })
            } else {
              commit('SET_DATA_CHATBOT_USAGE', [])
            }
            commit('UPDATE_LOADING_CHATBOT_USAGE', false)
            resolve(true)
          },
          (err) => {
            commit('SET_DATA_CHATBOT_USAGE', [])
            commit('UPDATE_PAGINATION_PAGE', {
              type: params.type,
              current: 1,
              total: 0,
              limit: 10,
            })
            commit('UPDATE_LOADING_CHATBOT_USAGE', false)
            reject(err)
          }
        )
    })
  },
  getCountData({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${BILLINGS_URL}/chatbot_ai/usage_log`, params.payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (res.data) {
              commit('UPDATE_PAGINATION_TOTAL', {
                type: params.type,
                total: res.data?.pagination?.total,
                limit: params.payload.limit,
              })
            }
            resolve(true)
          },
          (err) => {
            commit('UPDATE_PAGINATION_PAGE', {
              type: params.type,
              current: 1,
              total: 0,
              limit: 10,
            })
            commit('UPDATE_PAGINATION_BUTTON', {
              type: params.type,
              action: 'initial',
            })
            reject(err)
          }
        )
    })
  },
  updateButton({ commit }, params) {
    commit('UPDATE_PAGINATION_BUTTON', {
      type: params.type,
      action: params.action,
    })
  },
  updateChangePagination({ commit }, params) {
    commit('UPDATE_CHANGE_PAGINATION', { type: params.type, e: params.e })
  },
  exportLogData({ commit }, params) {
    return new Promise((resolve, reject) => {
      const payload = {
        ...params.payload,
        is_download: true,
      }
      requests.whatsapp
        .getService(`${BILLINGS_URL}/chatbot_ai/usage_log`, payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, 'my_data.csv')
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                const filename = `report_${params.type}_logs_${params.raw_date.start_date}_${params.raw_date.end_date}.csv`
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', filename)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Failed to download ' + err.error.messages[0] },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_LOADING_CHATBOT_USAGE(state, payload) {
    state.isLoadingChatbotUsageLog = payload
  },
  SET_DATA_CHATBOT_USAGE(state, payload) {
    state.responseChatbotUsageLog = payload
  },
  UPDATE_FILTER(state, payload) {
    state.filter.start_at = payload.start_at
    state.filter.end_at = payload.end_at
  },
  UPDATE_PAGINATION_PAGE(state, payload) {
    state.pagination[payload.type].limit = payload.limit
    state.pagination[payload.type].total = payload.total
    state.pagination[payload.type].total_page = Math.ceil(
      payload.total / payload.limit
    )
    state.pagination[payload.type].current = payload.current
  },
  UPDATE_PAGINATION_BUTTON(state, payload) {
    if (payload.action === 'next') {
      if (state.pagination[payload.type].current)
        state.pagination[payload.type].prev = false
      if (
        state.pagination[payload.type].current >=
        state.pagination[payload.type].total_page
      )
        state.pagination[payload.type].next = true
    } else if (payload.action === 'initial') {
      state.pagination[payload.type].next =
        state.pagination[payload.type].current >=
        state.pagination[payload.type].total_page
      if (state.pagination[payload.type].current <= 1)
        state.pagination[payload.type].prev = true
    } else {
      if (state.pagination[payload.type].current <= 1)
        state.pagination[payload.type].prev = true
      if (state.pagination[payload.type].current)
        state.pagination[payload.type].next = false
    }
  },
  UPDATE_CHANGE_PAGINATION(state, payload) {
    state.pagination[payload.type].prev = payload.e <= 1
    state.pagination[payload.type].next =
      payload.e >= state.pagination[payload.type].total_page
  },
  UPDATE_PAGINATION_TOTAL(state, payload) {
    state.pagination[payload.type].total_page = Math.ceil(
      payload.total / payload.limit
    )
  },
}
