import { getField, updateField } from 'vuex-map-fields'
import _ from 'lodash-core'
import requests from '../requests'
import { WHATSAPP_URL } from '@/assets/variables/endpoints'

export const state = () => ({
  responder: {
    during_office: {
      message: '',
      enabled: false,
    },
    outside_office: {
      message: '',
      enabled: false,
    },
    original_data: {
      during_office: {
        msg: '',
        status: false,
      },
      outside_office: {
        msg: '',
        status: false,
      },
    },
  },
  formattedOfficeHours: [],
  office: {
    time: [],
    time_list: [],
    working_days: {
      new: [],
      old: [],
    },
    working_days_old: [],
  },
  tag: {
    list: [],
    new: [],
  },
  templates: {
    list: [],
    formatter: [],
    edit: {
      id: 1,
      command: '',
      templates_content: '',
    },
  },
})

export const getters = {
  getField,
}

export const actions = {
  getTemplates({ commit, state }, params) {
    if (params.offset === 1) params.is_counted = true
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    // commit('layouts/UPDATE_CONTENTLOADING', { type: 'table', status: true }, { root: true })
    requests.whatsapp
      .getService(`${WHATSAPP_URL.template}/suggest_message`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          if (params.offset === 1)
            commit('layouts/SET_TOTAL_COUNT', res.meta.pagination.total, {
              root: true,
            })
          commit('TEMPLATES_FORMATTER', res.data)
          commit('SET_TEMPLATES', state.templates.formatter)
          commit('layouts/SET_META', res.meta, { root: true })
          // commit('layouts/UPDATE_CONTENTLOADING', { type: 'table', status: false }, { root: true })
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          // commit('layouts/UPDATE_CONTENTLOADING', { type: 'table', status: false }, { root: true })
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  getTemplatesDetail({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    const data = state.templates.list.find((item) => item.id === params)
    commit('SET_EDITTEMPLATES', data)
    commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
  },
  getTags({ commit, state }, params) {
    if (params.offset === 1) params.is_counted = true
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(`${WHATSAPP_URL.tags}`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          if (params.offset === 1)
            commit('layouts/SET_TOTAL_COUNT', res.meta.pagination.total, {
              root: true,
            })
          commit('SET_TAGS', res.data)
          commit('layouts/SET_META', res.meta, { root: true })
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  getRoomFilterTags({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${WHATSAPP_URL.tags}`, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('SET_TAGS', res.data)
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getRoomFilterTagsByPage({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${WHATSAPP_URL.tags}`, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('PUSH_TAGS', res.data)
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getOfficeHours({ commit }) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      commit(
        'layouts/UPDATE_CONTENTLOADING',
        { type: 'table', status: true },
        { root: true }
      )
      requests.whatsapp
        .getService(
          `${WHATSAPP_URL.business_hours}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('SET_OFFICE_HOURS', res.data)
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  getOfficeWorkingDays({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      commit(
        'layouts/UPDATE_CONTENTLOADING',
        { type: 'table', status: true },
        { root: true }
      )
      requests.whatsapp
        .getService(`${WHATSAPP_URL.business_hours}/working_days`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('WORKING_DAYS_FORMATTER', res.data)
            commit('SET_WORKING_DAYS', state.formattedOfficeHours)
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  updateDuringResponder({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .putService(`${WHATSAPP_URL.responder}/in_office_hours`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('UPDATE_DURING_AUTO_RESPONDER')
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Updated!',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  updateOutsideResponder({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .putService(`${WHATSAPP_URL.responder}/out_office_hours`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('UPDATE_OUTSIDE_AUTO_RESPONDER')
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Updated!',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  updateOfficeHours({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      requests.whatsapp
        .putService(`${WHATSAPP_URL.business_hours}/${params.id}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully Updated!',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  updateTemplates({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .putService(
        `${WHATSAPP_URL.template}/suggest_message/${params.id}`,
        params,
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('TEMPLATES_FORMATTER', [res.data])
          commit('SET_EDITTEMPLATES', state.templates.formatter[0])
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Updated!',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  updateTags({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(`${WHATSAPP_URL.tags}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  updateOfficeWorkingDays({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .putService(
        `${WHATSAPP_URL.business_hours}/working_days/${params.id}`,
        params,
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Updated!',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  addTags({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      requests.whatsapp
        .postService(`${WHATSAPP_URL.tags}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('UPDATE_NEW_TAGS', res.data)
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully Created!',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  addTemplates({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .postService(`${WHATSAPP_URL.template}/suggest_message`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Created!',
              item: '',
              callback: {
                text: 'OK',
                method: 'redirectToSettingsChatTemplates',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  addOfficeWorkingDays({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .postService(`${WHATSAPP_URL.business_hours}/working_days`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Created!',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  deleteTags({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .deleteService(`${WHATSAPP_URL.tags}`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  deleteTemplates({ commit, dispatch, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .deleteService(
        `${WHATSAPP_URL.template}/suggest_message/${params.id}`,
        {},
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          // commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          setTimeout(() => {
            dispatch('getTemplates', {
              limit: 10,
              offset: params.offset,
            })
          }, 1000)
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  recoverTag({ commit, state }, params) {
    const index = _.findIndex(state.tag.list, { name: params.tag })
    if (index > -1) {
      commit('conversation/UPDATE_TAGS', state.tag.list[index], { root: true })
    } else {
      commit(
        'conversation/UPDATE_TAGS',
        {
          id: new Date().getUTCMilliseconds(),
          name: params.tag,
        },
        { root: true }
      )
    }
  },
}

export const mutations = {
  updateField,
  TEMPLATES_FORMATTER(state, payload) {
    state.templates.formatter = []
    for (let index = 0; index < payload.length; index++) {
      const data = {
        id: payload[index].id,
        command: payload[index].short_code,
        templates_content: payload[index].content,
      }
      state.templates.formatter.push(data)
    }
  },
  WORKING_DAYS_FORMATTER(state, payload) {
    const businessHourId = payload[0].business_hour_id

    for (let i = 0; i < 7; i++) {
      const index = _.findIndex(payload, { day: i })
      if (index < 0) {
        payload.push({
          business_hour_id: businessHourId,
          close_time_at: '17:00',
          day: i,
          enabled: false,
          id: 'dummy',
          open_time_at: '07:00',
        })
      }
    }

    const dataFormatted = _.groupBy(payload, 'day')
    const workingDaysFormatted = []
    for (const i in dataFormatted) {
      const temp = []
      for (const j in dataFormatted[i]) {
        temp.push({
          id: dataFormatted[i][j].id,
          business_hour_id: dataFormatted[i][j].business_hour_id,
          start: dataFormatted[i][j].open_time_at,
          finish: dataFormatted[i][j].close_time_at,
        })
      }

      let day = ''

      switch (dataFormatted[i][0].day) {
        case 0:
          day = 'Sunday'
          break
        case 1:
          day = 'Monday'
          break
        case 2:
          day = 'Tuesday'
          break
        case 3:
          day = 'Wednesday'
          break
        case 4:
          day = 'Thursday'
          break
        case 5:
          day = 'Friday'
          break
        case 6:
          day = 'Saturday'
          break
      }
      workingDaysFormatted.push({
        day,
        day_index: dataFormatted[i][0].day,
        status: dataFormatted[i][0].enabled,
        hours: temp,
      })
    }

    state.formattedOfficeHours = workingDaysFormatted
    state.office.working_days.old = payload
  },
  SET_TAGS(state, payload) {
    state.tag.list = payload
  },
  PUSH_TAGS(state, payload) {
    payload.forEach((data) => {
      state.tag.list.push(data)
    })
  },
  CLEAR_TAG_LISTS(state) {
    state.tag.list = []
  },
  SET_TEMPLATES(state, payload) {
    state.templates.list = payload
  },
  SET_EDITTEMPLATES(state, payload) {
    state.templates.edit = payload
  },
  SET_OFFICE_HOURS(state, payload) {
    state.office.time = payload
  },
  SET_WORKING_DAYS(state, payload) {
    state.office.working_days.new = payload
  },
  SET_TIME_LIST(state, payload) {
    state.office.time_list = payload
  },
  UPDATE_TEMPLATES(state, payload) {
    state.templates.edit[payload.attribute] = payload.value
  },
  UPDATE_TAGS(state, payload) {
    state.tag.list[payload.index].tag = payload.data.tag
  },
  UPDATE_NEW_TAGS(state, payload) {
    state.tag.new = payload
  },
  UPDATE_INSERT_TAGS(state, payload) {
    state.tag.list.unshift(payload)
  },
  UPDATE_AUTO_RESPONDER(state, payload) {
    state.responder = payload
    state.responder.original_data = {
      during_office: {
        msg: payload.during_office.message,
        status: payload.during_office.enabled,
      },
      outside_office: {
        msg: payload.outside_office.message,
        status: payload.outside_office.enabled,
      },
    }
  },
  UPDATE_DURING_AUTO_RESPONDER(state) {
    state.responder.original_data.during_office = {
      msg: state.responder.during_office.message,
      status: state.responder.during_office.enabled,
    }
  },
  UPDATE_OUTSIDE_AUTO_RESPONDER(state) {
    state.responder.original_data.outside_office = {
      msg: state.responder.outside_office.message,
      status: state.responder.outside_office.enabled,
    }
  },
  UPDATE_WORKING_DAYS_STATUS(state, payload) {
    state.office.working_days.new[payload.index].status =
      !state.office.working_days.new[payload.index].status
  },
  UPDATE_WORKING_DAYS_HOUR(state, payload) {
    state.office.working_days.new[payload.index].hours[payload.indexs][
      payload.type
    ] = payload.value
  },
  DELETE_TAGS(state, payload) {
    state.tag.list.splice(payload.index, 1)
  },
  DELETE_TEMPLATES(state, payload) {
    state.templates.list.splice(payload.index, 1)
  },
}
